import React, { useState, useEffect } from "react"
import GitexPopupModal from "../../../core/GitexPopupModal"
import { Zap } from "lucide-react"
import { Sparkles } from "lucide-react"
import star from "../../../../assets/images/HeaderMenus/Stars.svg"
const AIHeroSection = () => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    setIsVisible(true)
  }, [])
  const [openGitex, setOpenGitex] = useState(false)

  const openGitexModal = () => {
    setOpenGitex(true)
  }
  const closeGitexModal = () => {
    setOpenGitex(false)
  }
  return (
    <>
      {openGitex && (
        <GitexPopupModal
          open={openGitex}
          closeGitexModal={closeGitexModal}
          url={"https://calendly.com/webmob/meet-us-at-ces-2025"}
        />
      )}
      <div className="relative min-h-screen text-white overflow-hidden">
        {/* Background particle effect */}
        <div
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: `url("https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2024/11/AIHero_Section.webp")`,
          }}
        />

        {/* Content container */}
        <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-20 pb-32 text-center">
          {/* AI Icon with glowing effect */}

          <div className="relative overflow-hidden rounded-xl h-40 w-40 mx-auto">
            <img
              src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2024/11/AI.webp"
              alt="AI Icon"
              width={240}
              height={240}
              className="object-cover"
            />
          </div>
          <div className="flex items-center justify-center">
            <button
              className="group relative px-4 md:px-8 py-3 rounded-full  text-white font-medium text-sm sm:text-base transition-all duration-300"
              style={{
                borderRadius: "40px",
                border: "4px solid rgba(255, 255, 255, 0.10)",
                background:
                  "var(--primary-secondary-prrimarry-335-fff, #335FFF)",
              }}
            >
              <div
                className="flex items-center gap-2"
                style={{
                  background:
                    "background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, #FFF 53%, rgba(255, 255, 255, 0.00) 100%);",
                }}
              >
                <img src={star} className="w-8 h-8" />
                <span>We Innovate. We Evolve. We Inspire.</span>
              </div>
              <div className="absolute inset-0 rounded-full bg-gradient-to-r from-blue-600/50 to-blue-500/50 blur-xl opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
            </button>
          </div>
          {/* Heading */}
          <div className="text-3xl md:text-6xl font-bold my-4 md:mb-4 text-center leading-snug md:leading-tight">
            <span
              className="bg-gradient-to-r from-cyan-300 to-green-400 text-transparent bg-clip-text font-extrabold"
              style={{
                backgroundImage:
                  "conic-gradient(from 43deg at 50% 50%, #FFF 4.652390107512474deg, #FFF 116.65143728256226deg, #FFD333 318.4312963485718deg)",
              }}
            >
              Empowering Businesses with AI Innovations & Solutions
            </span>
          </div>

          {/* Description */}
          <p className="text-xl font-medium text-center">
            <span className="block">
              {" "}
              Your trusted partner for tailored digital transformation and
              AI-driven solutions across industries.
            </span>
          </p>

          {/* CTA Button */}
          <div className="flex justify-center lg:justify-center w-full">
            <button
              className="my-4 md:my-8 lg:mt-6 bg-yellow-500 relative px-8 py-3 text-base leading-6 font-semibold text-shark-500  hover:bg-yellow-600 rounded-full"
              onClick={openGitexModal} // remove after gitex2022
            >
              Consult Our AI Experts
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default AIHeroSection
