import React from "react"
import H2 from "../../core/H2"
import P from "../../core/P"

const HiringModelBlock = ({ HiringModelBlock }) => {   
  const { hiringCard,ctaButton } = HiringModelBlock
  return (
    <>
      {/* <div className="my-2 text-center">
        <H2 title={title} />
      </div> */}
      <div className="mt-10 mb-24" data-aos="fade-up">
      <div className="flex  md:flex-row flex-col justify-center gap-8 px-2" >
        {hiringCard.map((item, i) => (
          <div
            className={`${item.popular ? "bg-blue-500" : "bg-white"
              } p-5 md:w-96 sm:w-4/5 w-full  rounded-md mx-auto md:mx-0`}
          >
            <div className="flex flex-row justify-between items-center">
              <div>
                <P
                  title={item?.subtitle}
                  noPad
                  className={
                    item?.popular ? "text-white font-semibold" : "font-semibold"
                  }
                />
                {
                  item?.tagline&&(
                    <P title={item?.tagline} noPad textSize="text-sm"   
                    className={
                      item?.popular && "text-white "
                    } />
                  )
                }
              </div>
              {item.popular && (
                <div className="bg-yellow-400 text-center px-3  rounded-lg">
                  <p>Popular</p>
                </div>
              )}
            </div>
            <H2
              title={item?.cardTitle}
              className="mt-5"
              theme={item.popular ? "white" : "#335FFF"}
            />
            <P
              title={item?.description}
              className={item.popular ? "text-white" : ""}
            />
          </div>
        ))}
      </div>
      {
        ctaButton&& (<div className="flex justify-center rounded-full mt-10 ">
        <a
          href={ctaButton?.url}
          className="inline-flex bg-blue-500 relative px-8 py-3 text-base leading-6 font-medium text-white hover:bg-blue-600 rounded border border-transparent"
          //target={ctaButton?.target}
        >
          {ctaButton?.title}
        </a>
      </div>)
      }

      </div>
    </>
  )
}

export default HiringModelBlock
