import React, { useState } from "react"
import Slider from "react-slick"
import { MessageSquare, Video, SearchIcon } from "lucide-react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./AIApproach.css"
import SliderComponent from "../../SliderComponent"

export default function AIApproach() {
  const [currentSlide, setCurrentSlide] = useState(0)

  const items = [
    {
      number: "01",
      title: "Discovery",
      icon: (
        <SearchIcon
          className="text-white"
          style={{ width: "var(--icon-size)", height: "var(--icon-size)" }}
        />
      ),

      description:
        "Identify business challenges and opportunities where AI can make an impact. This phase involves exploring potential use cases and defining the objectives of the AI solution.",
    },
    {
      number: "02",
      title: "Analysis & Planning",
      icon: (
        <Video
          className="text-white"
          style={{ width: "var(--icon-size)", height: "var(--icon-size)" }}
        />
      ),
      description:
        "Analyze the technical and operational requirements to ensure feasibility. Develop a structured plan and timeline to guide the project, considering scalability and resource allocation.",
    },
    {
      number: "03",
      title: "Design & Prototype",
      icon: (
        <Video
          className="text-white"
          style={{ width: "var(--icon-size)", height: "var(--icon-size)" }}
        />
      ),
      description:
        "Create user-centric designs and interactive prototypes to visualize the solution's functionality. This ensures alignment with your goals and streamlines the approval process.",
    },
    {
      number: "04",
      title: "Data Preparation",
      icon: (
        <Video
          className="text-white"
          style={{ width: "var(--icon-size)", height: "var(--icon-size)" }}
        />
      ),
      description:
        "Collect, clean, and structure data from various sources to ensure its quality and relevance. This step is crucial for training accurate and efficient AI models.",
    },
    {
      number: "05",
      title: "Model Development",
      icon: (
        <Video
          className="text-white"
          style={{ width: "var(--icon-size)", height: "var(--icon-size)" }}
        />
      ),
      description:
        "Build, train, and refine machine learning models using state-of-the-art frameworks. Rigorous testing and optimization ensure the model meets performance benchmarks.",
    },
    {
      number: "06",
      title: "Integration & Deployment",
      icon: (
        <Video
          className="text-white"
          style={{ width: "var(--icon-size)", height: "var(--icon-size)" }}
        />
      ),
      description:
        "Seamlessly integrate the AI solution into your systems with minimal disruption. The deployment process includes monitoring and iterative improvements for sustained efficiency.",
    },
  ]

  const sliderRef = React.useRef(null)

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: false,
    autoplaySpeed: 3000,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
    beforeChange: (current, next) => setCurrentSlide(next),
  }

  return (
    <div className="relative min-h-screen w-full bg-[#1a237e] overflow-hidden">
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{
          backgroundImage: `url("https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2024/11/Our-AI-Approach.webp")`,
        }}
      />
      <div className="relative max-w-screen-2xl mx-auto px-4 py-16">
        <div className="text-center mb-16">
          <h1
            className="text-3xl md:text-5xl font-bold text-white px-10 py-2 inline-block"
            style={{
              borderRadius: "40px",
              border: "6px solid rgba(255, 249, 249, 0.08)",
              background: "rgba(255, 255, 255, 0.16)",
            }}
          >
            Our AI Approach
          </h1>
        </div>

        <p className="text-center text-lg font-medium mb-12 max-w-3xl mx-auto text-white">
          At the heart of innovation, our tailored AI development process
          combines deep expertise and cutting-edge technology to deliver
          solutions that align perfectly with your business goals. From
          discovery to deployment, we ensure every phase is designed to maximize
          value and drive success.
        </p>

        <div className="relative md:px-8 md:py-12">
          <Slider ref={sliderRef} {...settings} className="gap-slider">
            {items.map((item, index) => (
              <SliderComponent
                key={index}
                number={item.number}
                title={item.title}
                icon={item.icon}
                description={item.description}
              />
            ))}
          </Slider>

          <div className="flex justify-center items-center gap-4 mt-8">
            <button
              onClick={() => sliderRef.current?.slickPrev()}
              className="w-12 h-12 rounded-full border border-white/30 bg-transparent flex items-center justify-center hover:bg-white/10 transition-colors"
            >
              <i
                className={`icon-slider-left-arrow text-white flex justify-center py-3 mx-0 active:text-blue-500`}
                style={{ fontSize: "20px" }}
              />
            </button>

            <span className="text-white text-lg font-bold">
              {currentSlide + 1} /{" "}
              <span className="text-white text-lg font-bold opacity-50 ">
                {items.length}
              </span>
            </span>
            <button
              type="button"
              onClick={() => sliderRef.current?.slickNext()}
              className="w-12 h-12 rounded-full border border-white/30 bg-transparent flex items-center justify-center hover:bg-white/10 transition-colors"
            >
              <i
                className={`icon-slider-right-arrow text-white flex justify-center py-3 mx-0 active:text-blue-500`}
                style={{ fontSize: "20px" }}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
