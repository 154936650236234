import React, { useRef, useState } from "react"
import Slider from "react-slick"
import "./casestudyslider.css"
const CaseStudySlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0)

  const cards = [
    {
      id: 1,
      title: "Melly App",
      subtitle: "Mental Wellness App Powered by AI Insights",
      description: (
        <>
          <ul className="list-disc pl-4 space-y-3 lg:space-y-6">
            <li>AI-based journals and therapy.</li>
            <li>
              CBT exercises to stress less and deal with negative thoughts.
            </li>
            <li>AI-based personality assessments and exercises.</li>
            {/* <li>
              MellyCoach delivers personalized insights on your journals and
              provides advice on your thoughts, feelings, and emotions.
            </li>
            <li>In-depth data analysis for tracking improvements.</li> */}
          </ul>
        </>
      ),
      image: {
        mediaItemUrl:
          "https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2024/12/melly.png",
        altText: "Melly App Screenshot",
      },
      ctaLink: {
        url: "https://webmobtech.com/casestudy/mental-wellness-app/",
        title: "Case Study",
        target: "_blank",
      },
    },
    {
      id: 2,
      title: "CRM for Real Estate",
      subtitle: "AI Powered CRM & Lead Management Software",
      description: (
        <>
          <ul className="list-disc pl-4 space-y-3 lg:space-y-6">
            <li>
              It's more important than ever to build deeper customer
              connections, manage and monitor your business in real time, and
              spin faster as the world continues to evolve rapidly with our CRM
              solution.
            </li>
            {/* <li>Contact management, lead routing & management.</li>
            <li>In-app call, chat & calendar appointment sync.</li> */}
            {/* <li>AI transcript of lead conversations.</li>
            <li>Sentiment & tone analysis using NLP for lead conversations.</li>
            <li>ML-based lead preferences.</li> */}
          </ul>
        </>
      ),
      image: {
        mediaItemUrl:
          "https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2024/11/Questnquiz_SliderImage.webp",
        altText: "Arkiv App Screenshot",
      },
      ctaLink: {
        url:
          "https://webmobtech.com/casestudy/real-estate-crm-software-solution/",
        title: "Case Study",
        target: "_blank",
      },
    },

    {
      id: 3,
      title: "Arkiv 360",
      subtitle: "AI-powered app for image recognition and text extraction",
      description: (
        <>
          <ul className="list-disc pl-4 space-y-3 lg:space-y-6">
            <li>
              Easy Uploads: Users can effortlessly upload images or folders with
              an intuitive web UI.
            </li>
            {/* <li>
              Real-Time Object Classification: The site uses computer vision to
              identify and categorize objects in real-time.
            </li>
            <li>
              Text Extraction: Integrated OCR and HWR technologies extract text,
              providing descriptions and transcripts of uploaded images.
            </li> */}
            {/* <li>
              Improved Performance: Deep learning trains new data for better
              performance, leveraging pre-trained models for accurate real-time
              recognition.
            </li> */}
          </ul>
        </>
      ),
      image: {
        mediaItemUrl:
          "https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2024/11/Questnquiz_SliderImage.webp",
        altText: "Arkiv App Screenshot",
      },
      ctaLink: {
        url: "https://webmobtech.com/casestudy/image-recognition-app/",
        title: "Case Study",
        target: "_blank",
      },
    },
    {
      id: 4,
      title: "Xeedle",
      subtitle:
        "AI-driven tool automating XPath generation for effortless testing",
      description: (
        <>
          <ul className="list-disc pl-4 space-y-3 lg:space-y-6">
            <li>
              Cross-Browser Compatibility: The extension supports Chrome and
              Firefox, providing users an apt way to create an XPath.
            </li>
            {/* <li>
              Manage Scrap History: Pro users can manage scrap history and
              benefit from advanced features, including copying scrap XPath
              files and improving the convenience of sharing and reusing scrap
              data.
            </li> */}
            {/* <li>
              Easy to Learn: The tool offers blogs, tutorials, and guides to
              help QA testers learn the technology easily and gain insights and
              best practices for testing.
            </li> */}
          </ul>
        </>
      ),
      image: {
        mediaItemUrl:
          "https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2024/11/Questnquiz_SliderImage.webp",
        altText: "Arkiv App Screenshot",
      },
      ctaLink: {
        url: "https://webmobtech.com/casestudy/testing-automation-tool/",
        title: "Case Study",
        target: "_blank",
      },
    },
    {
      id: 5,
      title: "QuestNQuiz",
      subtitle: "AI-generated quiz maker App",
      description: (
        <>
          <ul className="list-disc pl-4 space-y-3 lg:space-y-6">
            <li>
              Effortless Quiz Creation: Users can generate quizzes using
              keywords, adjust difficulty levels, and choose question formats
              including MCQs and true/false.
            </li>
            {/* <li>
              Innovative 'Scan and Make' Feature: Create quizzes by scanning
              text, with AI technology extracting keywords to generate questions
              automatically.
            </li> */}
            {/* <li>
              Simple Profile Setup: Easy signup process requiring basic personal
              details like name, email, and password.
            </li>
            <li>
              Seamless Quiz Sharing: Share quizzes with contacts, challenge
              others, and track scores on specific topics.
            </li> */}
          </ul>
        </>
      ),
      image: {
        mediaItemUrl:
          "https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2024/11/Questnquiz_SliderImage.webp",
        altText: "Arkiv App Screenshot",
      },
      ctaLink: {
        url: "https://webmobtech.com/casestudy/quiz-app-development/",
        title: "Case Study",
        target: "_blank",
      },
    },
  ]

  const textSliderRef = useRef(null)
  const imageSliderRef = useRef(null)

  const textSliderSettings = {
    dots: false,
    arrows: false,
    fade: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: imageSliderRef.current, // Synchronize with image slider
    beforeChange: (_, next) => setCurrentSlide(next), // Update current slide
  }

  const imageSliderSettings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: textSliderRef.current, // Synchronize with text slider
  }
  const handleClick = url => {
    console.log("URL:", url)
    window.open(url, "_blank") // This opens the link in a new tab.
  }

  const next = () => {
    textSliderRef.current.slickNext()
    imageSliderRef.current.slickNext()
  }

  const previous = () => {
    textSliderRef.current.slickPrev()
    imageSliderRef.current.slickPrev()
  }

  return (
    // <div className="px-4 sm:px-6 w-screen lg:px-12 bg-white py-4 sm:py-6 lg:py-12">
    //   <div className="container mx-auto lg:flex lg:items-center">
    //     {/* Left Side: Text Content */}

    //     {/* Right Side: Image Slider */}
    //     <div className="lg:w-1/2 flex flex-col justify-center p-7 px-6 lg:px-10 order-1 lg:order-2 casestudy-rounded casestudy-rounded-lg"
    //      style={{
    //       background: "linear-gradient(230deg, #335FFF 42.88%, #000 105.66%)",
    //     }}>
    //       <Slider
    //         ref={imageSliderRef}
    //         {...imageSliderSettings}
    //         className="image-slider"
    //       >
    //         {cards.map(card => (
    //           <div
    //             key={card.id}
    //             className="flex justify-center items-center h-full"
    //           >
    //             <img
    //               src={card.image.mediaItemUrl}
    //               alt={card.image.altText || "Solution"}
    //               className="rounded-r-3xl  w-full    object-cover"
    //             />
    //           </div>
    //         ))}
    //       </Slider>
    //     </div>
    //     <div
    //       className="lg:w-1/2 flex flex-col justify-center px-2 md:px-10 order-2 lg:order-1 lg:border-2 border-[rgba(44,35,232,0.2)]
    //     casestudy-slider-rounded casestudy-slider-rounded-lg"
    //     >
    //       <Slider
    //         ref={textSliderRef}
    //         {...textSliderSettings}
    //         className="text-slider"
    //       >
    //         {cards.map(card => (
    //           <div key={card.id} className="p-4  md:p-8">
    //             <h3 className="text-3xl md:text-5xl font-bold text-shark-500">
    //               {card.title}
    //             </h3>
    //             <div className="lg:mt-4 md:mt-3 mt-2 font-semibold text-blue-500 text-2xl md:text-4xl">
    //               {card.subtitle}
    //             </div>
    //             <div className="text-xl font-semibold text-shark-500 mt-8 lg:mt-12">
    //               {card.description}
    //             </div>
    //             <div className="flex justify-center lg:justify-start w-full cursor-pointer relative">
    //               <button
    //                 className="my-4 md:my-8 z-10 lg:mt-6 bg-yellow-500 relative px-8 py-3 text-base leading-6 font-semibold text-shark-500 cursor-pointer hover:bg-yellow-600 rounded-full"
    //                 onClick={() => handleClick(card.ctaLink.url)}
    //               >
    //                 Case Study
    //               </button>
    //             </div>
    //           </div>
    //         ))}
    //       </Slider>
    //     </div>
    //   </div>

    //   {/* Navigation Buttons */}
    //   <div className="flex justify-center items-center gap-4 mt-8">
    //     <button
    //       type="button"
    //       onClick={previous}
    //       className="w-12 h-12 rounded-full border border-blue-500 bg-transparent flex items-center justify-center hover:bg-white/10 transition-colors"
    //     >
    //       <i
    //         className={`icon-slider-left-arrow text-black flex justify-center py-3 mx-0 active:text-blue-500`}
    //         style={{ fontSize: "20px" }}
    //       />
    //     </button>
    //     <span className="text-blue-400 text-lg font-bold">
    //       {currentSlide + 1} /{" "}
    //       <span className="text-shark-500 text-lg font-bold opacity-50">
    //         {cards.length}
    //       </span>
    //     </span>
    //     <button
    //       type="button"
    //       onClick={next}
    //       className="w-12 h-12 rounded-full border border-blue-500 bg-transparent flex items-center justify-center hover:bg-white/10 transition-colors"
    //     >
    //       <i
    //         className={`icon-slider-right-arrow text-black flex justify-center py-3 mx-0 active:text-blue-500`}
    //         style={{ fontSize: "20px" }}
    //       />
    //     </button>
    //   </div>
    // </div>
    <>
    <div className="bg-white ">

      <div className="flex flex-col lg:flex-row px-4 sm:px-6 w-full lg:px-12 bg-white py-4 sm:py-6 lg:py-12 casestudy-height order-2 lg:order-1">
        {/* Sidebar - visible only on large screens */}
        <div
          className="lg:flex flex-col h-full w-full lg:w-1/2 p-4 lg:p-16 custom-rounded custom-rounded-lg justify-between custom-scrollbar
      lg:border-2 border-[rgba(44,35,232,0.2)]"
        >
          <nav className="h-full  pr-4 w-full">
            <Slider
              ref={textSliderRef}
              {...textSliderSettings}
              className="text-slider"
            >
              {cards.map(card => (
                <div key={card.id} className="p-4  md:p-8">
                  <h3 className="text-3xl md:text-5xl font-bold text-shark-500">
                    {card.title}
                  </h3>
                  <div className="lg:mt-4 md:mt-3 mt-2 font-semibold text-blue-500 text-2xl md:text-4xl">
                    {card.subtitle}
                  </div>
                  <div className="text-xl font-semibold text-shark-500 mt-8 lg:mt-12">
                    {card.description}
                  </div>
                  <div className="flex justify-center lg:justify-start w-full cursor-pointer relative">
                    <button
                      className="my-4 md:my-8 z-10 lg:mt-6 bg-yellow-500 relative px-8 py-3 text-base leading-6 font-semibold text-shark-500 cursor-pointer hover:bg-yellow-600 rounded-full"
                      onClick={() => handleClick(card.ctaLink.url)}
                    >
                      Case Study
                    </button>
                  </div>
                </div>
              ))}
            </Slider>
          </nav>
          <div className="flex-grow"></div>{" "}
          {/* This spacer helps ensure even margin distribution */}
        </div>
        {/* Main Content */}
        <div
          className=" h-full w-full lg:w-1/2 p-0 lg:p-6 overflow-y-auto  lg:border-2 border-[rgba(44,35,232,0.2)] order-1 lg:order-2
      custom-ai-rounded custom-ai-rounded-lg  space-y-6 flex flex-col justify-between"
          style={{
            background: "linear-gradient(230deg, #335FFF 42.88%, #000 105.66%)",
          }}
        >
          <section className="space-y-6 p-6 rounded-lg shadow-md lg:shadow-none  lg:h-full lg:flex lg:flex-col lg:justify-center">
            <Slider
              ref={imageSliderRef}
              {...imageSliderSettings}
              className="image-slider"
            >
              {cards.map(card => (
                <div
                  key={card.id}
                  className="flex justify-center items-center h-full"
                >
                  <img
                    src={card.image.mediaItemUrl}
                    alt={card.image.altText || "Solution"}
                    className="rounded-r-3xl  w-full    object-cover"
                  />
                </div>
              ))}
            </Slider>
          </section>
        </div>
      </div>
      <div className="flex justify-center items-center gap-4 my-8 bg-white">
        <button
          type="button"
          onClick={previous}
          className="w-12 h-12 rounded-full border border-blue-500 bg-transparent flex items-center justify-center hover:bg-white/10 transition-colors"
        >
          <i
            className={`icon-slider-left-arrow text-black flex justify-center py-3 mx-0 active:text-blue-500`}
            style={{ fontSize: "20px" }}
          />
        </button>
        <span className="text-blue-400 text-lg font-bold">
          {currentSlide + 1} /{" "}
          <span className="text-shark-500 text-lg font-bold opacity-50">
            {cards.length}
          </span>
        </span>
        <button
          type="button"
          onClick={next}
          className="w-12 h-12 rounded-full border border-blue-500 bg-transparent flex items-center justify-center hover:bg-white/10 transition-colors"
        >
          <i
            className={`icon-slider-right-arrow text-black flex justify-center py-3 mx-0 active:text-blue-500`}
            style={{ fontSize: "20px" }}
          />
        </button>
      </div>
      </div>
    </>
  )
}

export default CaseStudySlider
