import React, { useState } from "react"
import Slider from "react-slick"
import { MessageSquare, Video } from "lucide-react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./AISectionSlider.css"
import SliderComponent from "../../SliderComponent"

export default function AISectionSlider() {
  const [currentSlide, setCurrentSlide] = useState(0)

  const items = [
    {
      title: "Anomaly Detection",
      icon: (
        <Video
          className="text-white"
          style={{ width: "var(--icon-size)", height: "var(--icon-size)" }}
        />
      ),

      description:
        "Developed for a business client, this AI-powered POC detects anomalies in car mats, including grommet issues, stacked mats, and authenticity. WiTorch streams video to an app for AI analysis and results.",
    },
    {
      title: "Chat with PDF",
      icon: (
        <Video
          className="text-white"
          style={{ width: "var(--icon-size)", height: "var(--icon-size)" }}
        />
      ),
      description:
        "This AI-enabled POC lets users interact with PDFs via NLP. The chatbot extracts and responds to queries, making document content accessible, interactive, and easy to understand.",
    },
    {
      title: "Document OCR",
      icon: (
        <Video
          className="text-white"
          style={{ width: "var(--icon-size)", height: "var(--icon-size)" }}
        />
      ),
      description:
        " This POC for an aviation company uses OCR to extract and format text from handwritten and digital content, converting varied documents into a structured, readable format for streamlined processing.",
    },
    {
      title: "AI Agent for Food Ordering",
      icon: (
        <Video
          className="text-white"
          style={{ width: "var(--icon-size)", height: "var(--icon-size)" }}
        />
      ),
      description:
        " It leverages conversational AI for personalized food ordering, offering dish suggestions, guiding menus, and managing orders ensuring tailored recommendations, and improved customer satisfaction.",
    },
    {
      title: "IntelliTrack Live",
      icon: (
        <Video
          className="text-white"
          style={{ width: "var(--icon-size)", height: "var(--icon-size)" }}
        />
      ),
      description:
        "This POC delivers AI-powered live video analysis from cameras, drones, and saved videos. It detects, tracks objects, and adjusts configurations in real-time, ensuring efficient monitoring based on client needs.",
    },
    {
      title: "Speech-to-Text Translation",
      icon: (
        <Video
          className="text-white"
          style={{ width: "var(--icon-size)", height: "var(--icon-size)" }}
        />
      ),
      description:
        "This AI Speech-to-Text POC provides real-time, accurate translations, converting speech into text for seamless cross-language communication, ideal for meetings and global interactions.",
    },
  ]

  const sliderRef = React.useRef(null)

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: false,
    autoplaySpeed: 3000,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
    beforeChange: (current, next) => setCurrentSlide(next),
  }

  return (
    <div className="relative min-h-screen w-full bg-[#1a237e] overflow-hidden">
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{
          backgroundImage: `url("https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2024/11/Client_Testimonial.webp")`,
        }}
      />
      <div className="relative max-w-screen-2xl mx-auto px-4 py-16">
        <div className="text-center mb-16">
          <h1
            className="text-3xl md:text-5xl font-bold text-white px-10 py-2 inline-block"
            style={{
              borderRadius: "40px",
              border: "6px solid rgba(255, 249, 249, 0.08)",
              background: "rgba(255, 255, 255, 0.16)",
            }}
          >
            AI POCs
          </h1>
        </div>

        <p className="text-center text-lg font-medium mb-12 max-w-3xl mx-auto text-white">
          Our expertise spans healthcare, finance, and e-commerce, where we
          deliver innovative solutions that enhance efficiency and customer
          experience across these industries.
        </p>

        <div className="relative md:px-8 md:py-12">
          <Slider ref={sliderRef} {...settings} className="gap-slider">
            {items.map((item, index) => (
              <SliderComponent
                key={index}
                //number={item.number}
                title={item.title}
                icon={item.icon}
                description={item.description}
              />
            ))}
          </Slider>

          <div className="flex justify-center items-center gap-4 mt-8">
            <button
              onClick={() => sliderRef.current?.slickPrev()}
              className="w-12 h-12 rounded-full border border-white/30 bg-transparent flex items-center justify-center hover:bg-white/10 transition-colors"
            >
              <i
                className={`icon-slider-left-arrow text-white flex justify-center py-3 mx-0 active:text-blue-500`}
                style={{ fontSize: "20px" }}
              />
            </button>

            <span className="text-white text-lg font-bold">
              {currentSlide + 1} /{" "}
              <span className="text-white text-lg font-bold opacity-50 ">
                {items.length}
              </span>
            </span>
            <button
              type="button"
              onClick={() => sliderRef.current?.slickNext()}
              className="w-12 h-12 rounded-full border border-white/30 bg-transparent flex items-center justify-center hover:bg-white/10 transition-colors"
            >
              <i
                className={`icon-slider-right-arrow text-white flex justify-center py-3 mx-0 active:text-blue-500`}
                style={{ fontSize: "20px" }}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
