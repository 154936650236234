import React from "react";
import './SliderComponent.css';
export default function SliderComponent({ number, title, icon, description }) {
  return (
    <div className="card">
      {/* Large Number in Background */}
      <div
        className="absolute top-4 right-6 text-white text-8xl md:text-9xl font-bold select-none"
        style={{
          fill: "var(--functional-bg-light-bgf-0-f-4-ff, #F0F4FF)",
          opacity: 0.1,
        }}
      >
        {number}
      </div>

      {/* Card Content */}
      <div className="flex flex-col items-start h-full">
        <div className="w-20 h-20 md:w-24 md:h-24 lg:w-28 lg:h-28 rounded-2xl bg-[#4355ff] flex items-center justify-center mb-6 bg-gradient-to-r from-blue-600 to-blue-800">
          {icon}
        </div>
        <h3 className="text-xl lg:text-3xl font-bold text-white mb-4">
          {title}
        </h3>
        <p className="text-left font-normal text-lg text-white leading-relaxed overflow-hidden">
          {description}
        </p>
      </div>
    </div>
  );
}
