import React, { useState } from "react"
import H2 from "../../../core/H2"
import P from "../../../core/P"
import Twitter from "../../../../assets/images/store/Twitter Default.svg"
import LinkedIn from "../../../../assets/images/store/LinkedIn Default.svg"
import GitexPopupModal from "../../../core/GitexPopupModal"
import LinkedInhover from "../../../../assets/images/store/LinkedIn On Hover.svg"
import Twitterhover from "../../../../assets/images/store/Twitter On Hover.svg"
const CESSingleProfile = () => {
  const [openGitex, setOpenGitex] = useState(false)
  const [hoverState, setHoverState] = useState({
    Pramesh: false,
    Brad: false,
    Luke: false,
  })
  const [isTwitterHovered, setIsTwitterHovered] = useState(false)

  const openGitexModal = () => {
    setOpenGitex(true)
  }
  const closeGitexModal = () => {
    setOpenGitex(false)
  }
  const handleMouseEnter = name => {
    setHoverState(prev => ({
      ...prev,
      [name]: true,
    }))
  }

  const handleMouseLeave = name => {
    setHoverState(prev => ({
      ...prev,
      [name]: false,
    }))
  }
  const team = [
    {
      name: "Brad Bolton",
      title: "USA Director",
      image:
        "https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2024/11/Brad-Bolton.webp",
      linkedin: "https://www.linkedin.com/in/brad-bolton-98773b48/",
    },
    {
      name: "Pramesh Jain",
      title: "Founder & CEO",
      image:
        "https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2024/11/PrameshSir.png",
      linkedin: "https://www.linkedin.com/in/prameshjain/",
    },
    {
      name: "Luke Monroe",
      title: "USA Director",
      image:
        "https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2024/11/Luke-Monroe.webp",
      linkedin: "https://www.linkedin.com/in/lucasmonroe/",
    },
  ]
  return (
    <>
      {openGitex && (
        <GitexPopupModal
          open={openGitex}
          closeGitexModal={closeGitexModal}
          url={"https://calendly.com/webmob/meet-us-at-ces-2025"}
        />
      )}
      <div className="w-full bg-[#F8F9FF] py-12">
        <div className="font-serif font-bold lg:text-5xl text-3xl  tracking-normal text-center text-shark-500">
          Meet the Visionaries Driving Innovation at{" "}
          <span className="text-blue-500">WebMobTech</span>
        </div>
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center mb-12">
            <div className="lg:text-xl md:text-xl text-lg text-center font-medium text-shark-500 mt-4">
              WebMob Technologies will be represented at CES 2025 by <b>Mr. Pramesh
              Jain</b> (Founder & CEO) <br/>and, <b>Mr. Luke Monroe</b> and <b>Mr. Brad Balton</b> (USA
              Directors)
            </div>
            <div className="lg:text-xl md:text-xl text-lg text-center font-medium text-shark-500 mt-4">
              Pramesh, the visionary behind WebMobTech, founded the company in
              2010 with the motto <b>"Think Bigger, Make Better!"</b> <br />
              Luke and Brad bring extensive U.S. market expertise, driving
              innovation and strategic growth.
            </div>
            <div className="mt-8  flex justify-center">
              <button
                className="bg-yellow-500 relative px-8 py-3 text-base leading-6 font-semibold text-shark-500  hover:bg-yellow-600 rounded"
                onClick={openGitexModal} // remove after gitex2022
              >
                Schedule a Meeting
              </button>
            </div>{" "}
          </div>

          <div className="grid md:grid-cols-3 gap-12 mt-16">
            {team.map(member => (
              <div key={member.name} className="flex flex-col items-center">
                <div className="self-center lg:w-52 lg:h-52 md:w-52 md:h-52 w-40 h-40">
                  <img
                    src={member.image}
                    alt={member.name}
                    width="180"
                    className="w-60 rounded-full"
                    height="180"
                    loading="lazy"
                  />
                </div>
                <div className="text-2xl font-bold text-shark-500 pt-8">
                  {member.name}
                </div>
                <div className="text-lg font-medium text-shark-500 mt-2">
                  {member.title}
                </div>
                <div className="flex mt-4">
                  <a
                    href={member.linkedin}
                    className="py-2 inline-block text-white"
                    target="_blank"
                    rel="noreferrer"
                    onMouseEnter={() => handleMouseEnter(member.name)}
                    onMouseLeave={() => handleMouseLeave(member.name)}
                  >
                    <img
                      src={hoverState[member.name] ? LinkedInhover : LinkedIn}
                      alt="LinkedIn"
                      className="h-8"
                      loading="lazy"
                      width="32px"
                      height="32px"
                    />
                    <span className="sr-only">LinkedIn</span>
                  </a>
                  {/* <a
                    href={member.twitter}
                    className="py-2 inline-block text-white ml-5 "
                    target="_blank"
                    rel="noreferrer"
                    onMouseEnter={() => setIsTwitterHovered(true)}
                    onMouseLeave={() => setIsTwitterHovered(false)}
                  >
                    <img
                      src={isTwitterHovered ? Twitterhover : Twitter}
                      alt="Twitter"
                      className="h-8"
                      loading="lazy"
                      width="32px"
                      height="32px"
                    />
                    <span className="sr-only">Twitter</span>
                  </a> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default CESSingleProfile
