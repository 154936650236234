import React, { useState, useEffect } from "react"

const AIIndustries = () => {
  const [isDesktop, setIsDesktop] = useState(false)

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  })

  const handleResize = () => {
    setIsDesktop(window.innerWidth < 650)
  }

  return (
    <>
      <div className="relative">
        <div
          className="inset-0 bg-cover"
          style={{
            backgroundImage:
              'url("https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2024/11/Industries_We_Excel_In.webp")',
          }}
        >
          <div className="relative h-full max-w-screen-2xl mx-auto px-4 py-16">
            <div className="text-center mb-8">
              <h1
                className="inline-block text-3xl md:text-5xl font-bold text-white px-10 py-2"
                style={{
                  borderRadius: "40px",
                  border: "6px solid rgba(255, 249, 249, 0.08)",
                  background: "rgba(255, 255, 255, 0.16)",
                }}
              >
                Industries We Excel In
              </h1>
            </div>
            <div className="text-center mb-12">
              <p className="text-lg text-white max-w-3xl mx-auto">
                Our expertise spans healthcare, finance, and e-commerce, where
                we deliver innovative solutions that enhance efficiency and
                customer experience across these industries.
              </p>
            </div>
            <div className="flex justify-center">
              <img
                src={
                  isDesktop
                    ? "https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2024/12/Industruies-Mobile-reponsive.png"
                    : "https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2024/12/industries.webp"
                }
                alt="Industries"
                className="max-w-full h-auto object-cover rounded-lg"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AIIndustries
